import React from "react"
import firstBenefit from "@images/common/benefit1.svg"
import thirdBenefit from "@images/common/benefit3.svg"
import hat from "@images/common/hat.svg"
import tax from "@images/common/tax.svg"
import { LayoutWrapper } from "../../../../styles/CommonStyles"
import BenefitsFlippableListSection from "../../../Common/CommonSections/BenefitsFlippableListSection"

const listItems = [
  {
    title: "Full commitment",
    icon: firstBenefit,
    description: `
            The Impressit team focuses all their attention on delivering great products by assigning full-time dedicated teams to every project.
            We thoroughly analyze your needs and requirements and put every effort into developing an outstanding product
            for you while you are able to focus on the other parts of your business.
        `,
  },
  {
    title: "Time & costs savings",
    icon: tax,
    description: `
            We kick-start the project quickly, and by objectively evaluating the scope of work and the budget, we effectively deliver results.
            By hiring Impressit as your perfect offshore software product development company, you don’t need to look for compromises
            between quality, money, and time. 
        `,
  },
  {
    title: "Reduced risks",
    icon: thirdBenefit,
    description: `
            At the beginning of cooperation, we draw up a detailed work plan and consider potential problems, obstacles, and risks,
            and immediately look for ways to avoid or solve them. Having a reliable and skilled team of software engineers by your
            side guarantees the best results.
        `,
  },
  {
    title: "Experience",
    icon: hat,
    description: `
            Our software product development company consists of highly qualified and confident professionals who are always eager to improve. 
            The Impressit team is passionate about software development, loves finding out-of-the-box solutions and is ready for challenging tasks, 
            so you can be sure that your project is in good hands.
        `,
  },
]

const WhyChooseSection = () => {
  return (
    <LayoutWrapper>
      <BenefitsFlippableListSection configuration={listItems} />
    </LayoutWrapper>
  )
}

export default WhyChooseSection
